<template>
  <div
    v-if="loggedUser.roles.includes('super-admin')"
    class="card"
  >
    <div
      v-b-toggle="`collapseNotes`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Traces</a> <span class="badge bg-light-success">{{ notes.length }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseNotes`"
    >
      <div class="card-body">
        <div
          v-for="note, index in notes"
          :key="note.id"
          class="row"
        >
          <div class="col">
            <div class="d-flex justify-content-left align-items-center mb-2">
              <div class="avatar-wrapper">
                <div class="avatar  me-1">
                  <img
                    src="../../../../assets/images/avatars/2.png"
                    alt="Avatar"
                    height="32"
                    width="32"
                  >
                </div>
              </div><div class="d-flex flex-column">
                <span class="fw-bolder">{{ note.user.name }}</span><small class="emp_post text-muted">{{ note.date }} at {{ note.hour }}h</small>
              </div>
            </div>
            <div v-if="note.typology">
              {{ labels['type'] }}: {{ note.typology.name }}
            </div>
            <div
              class="mt-1 mb-1"
              v-html="note.note"
            />
            <a
              v-if="note.file"
              :href="note.file.url"
              target="_blank"
              class="me-1"
            ><i data-feather="eye" /> {{ note.file.name }}</a>
            <p
              v-if="note.user.id == user.id"
              class="mt-1"
            >
              <a
                v-if="canEdit"
                class="text-danger"
                @click="deleteNote(note.id, index)"
              ><i data-feather="trash-2" /> Delete note</a>
            </p>
            <hr>
          </div>
        </div>
        <div
          v-if="canEdit"
          class="row"
        >
          <div class="col-12">
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >{{ labels['content'] }}</label>
              <quill-editor v-model="newNote" />
            </div>
          </div>
          <div class="col-4">
            <label
              for=""
              class="form-label"
            >{{ labels['type'] }}</label>
            <v-select
              v-model="type"
              label="name"
              :options="typologies"
              :get-option-key="option => option.id"
            />
          </div>
          <div class="col-4">
            <label
              for=""
              class="form-label line-break"
            >{{ labels['file'] }}</label>
            <div>
              <input
                :ref="`trace-file`"
                type="file"
                @change="setFile()"
              >
            </div>
          </div>
          <div class="col-4" />
          <button
            class="btn btn-dark mt-1 w-auto ms-1"
            @click="saveNewNote"
          >
            Save note
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  props: {
    notes: { type: Array, required: false, default: () => [] },
    canEdit: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      newNote: '',
      type: null,
      attachFile: null,
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/admin',
      loggedUser: 'auth/admin',
      typologies: 'traceTypologies/items',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.traces`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }

    if (this.typologies.length === 0) {
      this.$store.dispatch('traceTypologies/fetch')
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async saveNewNote() {
      const date = new Date()
      const note = {
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        hour: `${date.getHours()}:${date.getMinutes()}`,
        note: this.newNote,
        userId: this.user.id,
        user: this.user,
        trace_typology_id: this.type ? this.type.id : null,
        file: this.attachFile,
        trace_url: this.$route.name.replace('create', 'edit'),
      }

      /* const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/internal-notes/edit/`, note) // SAVE -> si no le llega id le pone tipo User y el id del usuario logueado
      this.$emit('saveNewNote', resp.data.data) // Hace push al array */

      this.$emit('saveNewNote', note) // Hace push al array

      Vue.prototype.$saveFiles([this.attachFile])

      this.newNote = ''
      this.type = null
      this.attachFile = null
    },
    async deleteNote(id, index) {
      if (id) {
        try {
          const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/internal-notes/delete/${id}`)
          if (resp.status === 200) {
            Vue.swal('Personal note deleted successfully!', '', 'success')
            this.notes.splice(this.notes.findIndex(x => x.id === id), 1)
          } else {
            Vue.swal('Error on deleting the note.', 'Please, try it again', 'error')
          }
        } catch (e) {
          Vue.swal('Error on deleting the note.', 'Please, try it again', 'error')
        }
      } else {
        this.notes.splice(index, 1)
        this.newNote = 'Insert text here ...'
      }
    },
    setFile() {
      const file = this.$refs['trace-file'].files[0]
      this.attachFile = {
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        original_name: file.name,
        url: URL.createObjectURL(file),
        description: '-',
        to: '',
      }
    },
  },
}
</script>
